import {
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
  IconButton,
  DialogFooter,
  Button,
} from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import { PriceHistory } from '@/types'
import Icon from '@/components/Icon'
// import Loader from '@/components/Loader'
// import {  } from '@/actions/parent-products'
//
import FormProvider, { useForm, yupResolver, Yup } from '@/components/HookForm'
import Image from '@/components/Image'

type Props = {
  open: boolean
  handleOpen: () => void
  currentMinPriceProduct: PriceHistory.CurrentMinPriceProduct
}

export default function PriceAlertDialog({
  open,
  handleOpen,
  currentMinPriceProduct,
}: Props) {
  const d = useDictionary('price_history_and_alert')

  // const [errorMsg, setErrorMsg] = useState('')

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(d('email_required')).email(d('email_invalid')),
    password: Yup.string().required(d('password_required')),
  })

  const defaultValues = {
    email: '',
    password: '',
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  // const {
  //   reset,
  //   handleSubmit,
  //   formState: { isSubmitting },
  // } = methods

  // const onSubmit = handleSubmit(async (data) => {
  //   // const result = await signIn('credentials', {
  //   //   email: data.email,
  //   //   password: data.password,
  //   //   redirect: false,
  //   // })
  //   //
  //   // if (!result?.ok) {
  //   //   reset()
  //   //   setErrorMsg(result?.error || '')
  //   //   return
  //   // }
  // })

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className='max-h-screen overflow-y-auto rounded-none'
      size='md'
    >
      {/*<FormProvider methods={methods} /!*onSubmit={onSubmit}*!/>*/}
      <FormProvider methods={methods}>
        <DialogHeader className='justify-between pb-0'>
          <Typography variant='h3' className='text-lg font-bold text-primary'>
            {d('price_alert_popup_title')}{' '}
          </Typography>
          <IconButton
            aria-label={d('price_alert_popup_close_btn_title')}
            color='white'
            onClick={handleOpen}
            className='!shadow-none focus:!shadow-none'
          >
            <Icon size='10px' icon='cross' className='block' />
          </IconButton>
        </DialogHeader>
        <DialogBody className='grid grid-cols-1 gap-2 overflow-auto md:grid-cols-[2fr_1fr]'>
          <div className='order-2 md:order-1'>
            <Typography variant='h3' className='text-lg font-bold text-primary'>
              {d('price_alert_popup_title')}
            </Typography>
          </div>
          <div className='order-1 flex gap-2 border p-2 md:order-2 md:flex-col'>
            <div className='relative flex h-full min-h-[80px] w-[110px] items-center justify-center md:h-44 md:w-full'>
              <Image
                className='absolute inset-x-0 inset-y-0 size-full object-contain object-center'
                src={currentMinPriceProduct.media.imageUrl}
                width={260}
                height={260}
                alt={
                  currentMinPriceProduct?.name ||
                  currentMinPriceProduct.media?.bucket
                }
              />
            </div>
            <div className='flex-1'>
              <Typography
                variant='h3'
                className='line-clamp-[2] overflow-hidden text-ellipsis pb-2 text-left text-md font-normal text-primary'
              >
                <span className='line-clamp-[2] leading-normal'>
                  {currentMinPriceProduct.name}
                </span>
              </Typography>
              <div className='flex items-center justify-between gap-2'>
                <span className='text-sm text-primary'>
                  {d('price_alert_popup_price_info_title')}
                </span>
                <Typography
                  variant='lead'
                  className={`text-left text-xl font-medium text-primary md:text-right`}
                >
                  {currentMinPriceProduct.price}
                </Typography>
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter className='ju flex-col bg-gray-200'>
          <div className='mb-4 w-full'>
            <Typography
              variant='h3'
              className='text-lg font-bold text-primary md:text-2xl'
            >
              {d('price_alert_popup_info_title')}{' '}
            </Typography>
            <Typography
              variant='paragraph'
              className='text-base text-secondary'
            >
              {d('price_alert_popup_info_description')}{' '}
            </Typography>
          </div>

          <Button
            className='w-full bg-primary text-center text-white'
            size='md'
          >
            {d('price_alert_submit_btn_title')}
          </Button>
        </DialogFooter>
      </FormProvider>
    </Dialog>
  )
}
