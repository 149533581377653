import { PriceHistory as PriceHistoryTypes } from '@/types'
import {  Typography } from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import React, { useState, useCallback, useMemo } from 'react'
import IconSvg from '@/components/IconSvg'
import Chart, { useChart, ChartOptions } from '@/components/Chart'
import { fetchParentProductPriceHistory } from '@/actions/parent-products'
import Loader from '@/components/Loader'

import PriceGraphDialog from './components/price-graph-dialog'
import PriceAlertDialog from './components/price-alert-dialog'
import AnchorLink from './components/anchor-link'
import { generatePriceAndDateArrays } from './utils/priceDateUtils'

type Props = {
  productTitle: string
  productId: number
  productVariantId?: number | undefined
  history: PriceHistoryTypes.PriceHistory
  className?: string
  anchorLink?: { id?: string; icon?: string; text?: string }
}

export function PriceHistory({
  history,
  productId,
  productVariantId,
  productTitle,
  className,
  anchorLink,
}: Props) {
  const d = useDictionary('price_history_and_alert')
  const [activeDateRange, setActiveDateRange] = useState(history.dateRange || 0)
  const [priceStats, setPriceStats] = useState(history.stats)
  const [priceHistory, setPriceHistory] = useState(history.priceHistory)
  const [popupOpen, setPopupOpen] = useState({
    priceHistory: false,
    priceAlert: false,
  })
  const [loading, setLoading] = useState({ priceHistory: false })

  const changeDateRange = useCallback(
    async (range: number) => {
      setActiveDateRange(range)
      setLoading((prev) => ({ ...prev, priceHistory: true }))

      try {
        const { data } = await fetchParentProductPriceHistory(
          productId,
          range,
          productVariantId
        )

        setPriceHistory(data.priceHistory)
        setPriceStats(data.stats)
      } catch (error) {
        console.error('Error fetching price history:', error)
      } finally {
        setLoading((prev) => ({ ...prev, priceHistory: false }))
      }
    },
    [productId]
  )

  const togglePopup = useCallback(
    (popup: 'priceHistory' | 'priceAlert', state: boolean) => {
      setPopupOpen((prev) => ({ ...prev, [popup]: state }))
    },
    []
  )

  const { prices, dates } = useMemo(
    () => generatePriceAndDateArrays(priceHistory),
    [priceHistory]
  )

  const options: ChartOptions = useChart({
    chart: { events: { click: () => togglePopup('priceHistory', true) } },
    tooltip: {
      marker: { show: false },
      y: {
        title: { formatter: () => '' },
        formatter(val) {
          return `${val} ${history.currency}`
        },
      },
      x: {
        formatter: function (value, opts) {
          return (
            priceHistory[opts.dataPointIndex]?.dateFormatted || value
          ).toString()
        },
      },
    },
    yaxis: {
      show: false,
      labels: { show: false, formatter: (value) => value.toFixed(2) },
    },
    xaxis: {
      labels: { show: false },
      categories: dates,
    },
  })

  return (
    <>
      <div className={className}>
        <MobileActions
          d={d}
          openPriceHistoryModal={() => togglePopup('priceHistory', true)}
          openPriceAlert={() => togglePopup('priceAlert', true)}
          anchorLink={anchorLink}
        />
        <DesktopPriceHistory
          d={d}
          history={history}
          activeDateRange={activeDateRange}
          changeDateRange={changeDateRange}
          loading={loading}
          options={options}
          series={[{ data: prices }]}
          openPriceAlert={() => togglePopup('priceAlert', true)}
        />
      </div>

      <PriceGraphDialog
        open={popupOpen.priceHistory}
        productTitle={productTitle}
        productId={productId}
        productVariantId={productVariantId}
        handleOpen={() => togglePopup('priceHistory', false)}
        history={priceHistory}
        activeRange={activeDateRange}
        dateRanges={history?.dateRanges}
        stats={priceStats}
        currency={history.currency}
        // dialogFooterActions={
        //   <Button
        //     className='flex items-center gap-2 bg-primary text-white'
        //     size='sm'
        //     onClick={() => {
        //       togglePopup('priceHistory', false)
        //       togglePopup('priceAlert', true)
        //     }}
        //   >
        //     {d('price_alarm_btn_title')}
        //     <IconSvg className='size-5 fill-current' icon='alarm' />
        //   </Button>
        // }
      />

      <PriceAlertDialog
        open={popupOpen.priceAlert}
        handleOpen={() => togglePopup('priceAlert', false)}
        currentMinPriceProduct={{
          ...history.currentMinPriceProduct,
          id: productId,
          name: productTitle,
        }}
      />
    </>
  )
}

const MobileActions = ({
  d,
  openPriceHistoryModal,
  // openPriceAlert,
  anchorLink,
}: {
  d: any
  openPriceHistoryModal: () => void
  openPriceAlert: () => void
  anchorLink?: { id?: string; icon?: string; text?: string }
}) => (
  <div className='mb-4 grid grid-cols-2 md:mt-4 lg:hidden'>
    {!!(anchorLink && anchorLink.id && anchorLink.icon && anchorLink.text) && (
      <AnchorLink
        id={anchorLink.id}
        icon={anchorLink.icon}
        text={anchorLink.text}
        className='border-r'
      />
    )}
    <div
      onClick={openPriceHistoryModal}
      className='flex cursor-pointer flex-col items-center justify-center gap-2  px-2 py-1 text-md sm:text-base'
    >
      <IconSvg className='size-6 fill-current' icon='graph' />
      {d('price_history_btn_title')}
    </div>
    {/*<div*/}
    {/*  onClick={openPriceAlert}*/}
    {/*  className='flex cursor-pointer flex-col items-center justify-center gap-2 px-2 py-1 text-md sm:text-base'*/}
    {/*>*/}
    {/*  <IconSvg className='size-6 fill-current' icon='alarm' />*/}
    {/*  {d('price_alarm_btn_title')}*/}
    {/*</div>*/}
  </div>
)

const DesktopPriceHistory = ({
  d,
  history,
  activeDateRange,
  changeDateRange,
  loading,
  options,
  series,
  // openPriceAlert,
}: any) => (
  <div className='hidden lg:flex lg:flex-col lg:items-center lg:gap-2'>
    <div className='flex w-full items-center justify-between'>
      <Typography
        as='p'
        variant='h3'
        className='line-clamp-[2] overflow-hidden text-ellipsis text-left text-lg font-bold text-primary'
      >
        {d('price_alarm_btn_title')}
      </Typography>
      <div className='flex items-center gap-3'>
        {Object.keys(history?.dateRanges)?.map((key) => (
          <div
            key={key}
            onClick={() => changeDateRange(Number(key))}
            className={`cursor-pointer ${key === activeDateRange.toString() ? 'font-bold text-black' : ''}`}
          >
            {history?.dateRanges[key]?.shortName}
          </div>
        ))}
      </div>
    </div>
    <div className='relative h-[200px] w-full'>
      <Loader
        loading={loading.priceHistory}
        className='absolute bg-transparent backdrop-blur-sm'
        iconClassName='!fill-black'
      />
      <Chart options={options} series={series} type='line' height={200} />
    </div>
    {/*<Button*/}
    {/*  className='flex items-center gap-2 bg-primary leading-[20px] text-white'*/}
    {/*  size='sm'*/}
    {/*  onClick={openPriceAlert}*/}
    {/*>*/}
    {/*  {d('price_alarm_btn_title')}*/}
    {/*  <IconSvg className='size-5 fill-current' icon='alarm' />*/}
    {/*</Button>*/}
  </div>
)
