import React, { useState, useEffect, ReactNode } from 'react'
import {
  Dialog,
  DialogBody,
  DialogHeader,
  Typography,
  IconButton,
  DialogFooter,
} from '@material-tailwind/react'
import { useDictionary } from '@/providers'
import { PriceHistory } from '@/types'
import Chart from '@/components/Chart/chart'
import { ChartOptions, useChart } from '@/components/Chart'
import Icon from '@/components/Icon'
import Loader from '@/components/Loader'
import { fetchParentProductPriceHistory } from '@/actions/parent-products'

import PriceStat from './price-state'
import { generatePriceAndDateArrays } from '../utils/priceDateUtils'

type Props = {
  productTitle: string
  productId: number
  productVariantId?: number
  open: boolean
  history: PriceHistory.HistoryItem[]
  handleOpen: () => void
  activeRange: number
  dateRanges: PriceHistory.DateRanges
  stats: PriceHistory.Stats
  currency: string
  dialogFooterActions?: ReactNode
}

export default function PriceGraphDialog({
  productTitle,
  open,
  handleOpen,
  history,
  activeRange = 0,
  dateRanges = {},
  productId,
  productVariantId,
  stats,
  currency,
  dialogFooterActions = null,
}: Props) {
  const d = useDictionary('price_history_and_alert')

  const [activeDateRange, setActiveDateRange] = useState(activeRange)
  const [priceStats, setPriceStats] = useState(stats)

  const [loading, setLoading] = useState(false)
  const [priceHistory, setPriceHistory] = useState(history)

  useEffect(() => {
    setActiveDateRange(activeRange)
    setPriceStats(stats)
    setPriceHistory(history)
  }, [activeRange, stats, history])

  const changeDateRange = async (range: number) => {
    if (range === activeDateRange) return

    setActiveDateRange(range)
    setLoading(true)

    try {
      const { data } = await fetchParentProductPriceHistory(
        productId,
        range,
        productVariantId
      )
      setPriceHistory(data.priceHistory)
      setPriceStats(data.stats)
    } catch (error) {
      console.error('Error fetching price history:', error)
    } finally {
      setLoading(false)
    }
  }

  const { prices, dates, lastPrice } = generatePriceAndDateArrays(priceHistory)

  const options: ChartOptions = useChart({
    tooltip: {
      marker: { show: false },
      y: {
        title: { formatter: () => '' },
        formatter(val) {
          return `${val} ${currency}`
        },
      },
      x: {
        formatter: function (value, opts) {
          return (
            priceHistory[opts.dataPointIndex]?.dateFormatted || value
          ).toString()
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => value.toFixed(2),
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd.MM.yy',
      },
      categories: dates,
    },
    annotations: {
      yaxis: [
        {
          y: lastPrice,
          borderColor: '#3b82f6',
          strokeDashArray: 6,
          label: {
            borderColor: '#3b82f6',
            style: {
              color: '#fff',
              background: '#3b82f6',
            },
            text: `${lastPrice.toFixed(2)}`,
            textAnchor: 'start',
            offsetX: 8,
            offsetY: 6,
          },
        },
      ],
    },
  })

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      className='max-h-screen overflow-y-auto rounded-none'
      size='md'
    >
      <DialogHeader className='justify-between'>
        <Typography variant='h3' className='text-lg font-bold text-primary'>
          {d('price_history_popup_title')}{' '}
          {productTitle && (
            <Typography as='span' variant='small' className='font-medium'>
              {productTitle}
            </Typography>
          )}
        </Typography>
        <IconButton
          aria-label={d('price_history_popup_close_btn_title')}
          color='white'
          onClick={handleOpen}
          className='!shadow-none focus:!shadow-none'
        >
          <Icon size='10px' icon='cross' className='block' />
        </IconButton>
      </DialogHeader>
      <DialogBody className='overflow-auto'>
        <div className='flex items-center justify-end gap-3'>
          {Object.entries(dateRanges).map(([key, range]) => (
            <div
              key={key}
              onClick={() => changeDateRange(Number(key))}
              className={`cursor-pointer ${Number(key) === activeDateRange ? 'font-bold text-black' : ''}`}
            >
              {range.shortName}
            </div>
          ))}
        </div>
        <div className='relative'>
          <Loader
            loading={loading}
            className='absolute bg-transparent backdrop-blur-sm'
            iconClassName='!fill-black'
          />
          <div className='h-[294px]'>
            <Chart
              options={options}
              series={[{ data: prices }]}
              type='area'
              height={294}
            />
          </div>
          <div className='py-2.5'>
            {priceStats?.min && (
              <PriceStat
                label={d('price_history_popup_lowest_price')}
                data={priceStats?.min}
              />
            )}
            {priceStats?.avg && (
              <PriceStat
                label={d('price_history_popup_average_price')}
                data={priceStats?.avg}
              />
            )}
            {priceStats?.max && (
              <PriceStat
                label={d('price_history_popup_highest_price')}
                data={priceStats?.max}
              />
            )}
          </div>
        </div>
      </DialogBody>
      {!!dialogFooterActions && (
        <DialogFooter className='justify-center'>
          {dialogFooterActions}
        </DialogFooter>
      )}
    </Dialog>
  )
}
